import styled from "@emotion/styled";
import React from "react";
import OutlinedButton from "../button/outlined";
import FadingDivider from "../divider/fading";
import MuiLink from "../mui-link";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export enum ErrorAction {
  GO_TO_HOME,
  RELOAD,
}

interface ErrorActionProps {
  action?: ErrorAction;
}

const ErrorButton = styled(OutlinedButton)`
  max-width: 25rem;
`;

export const ErrorDivider = styled(FadingDivider)`
  max-width: 25rem;
`;

export interface ErrorProps extends ErrorActionProps {
  style?: React.CSSProperties;
}

export const Action: React.FC<ErrorActionProps> = ({
  action = ErrorAction.GO_TO_HOME,
}) => {
  switch (action) {
    case ErrorAction.GO_TO_HOME:
      return (
        <ErrorButton component={MuiLink} trackingName="Error Go Home" href="/">
          Gå till startsidan
        </ErrorButton>
      );

    case ErrorAction.RELOAD:
      return (
        <ErrorButton
          trackingName="Error Refresh"
          onClick={() => window.location.reload()}
        >
          Uppdatera sidan
        </ErrorButton>
      );
  }

  return null;
};
