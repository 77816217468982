import styled from "@emotion/styled";
import React from "react";
import { H1 } from "../typography";
import { Action, Container, ErrorDivider, ErrorProps } from "./common";

export const ErrorHeading = styled(H1)`
  text-align: center;
`;

export const ErrorSubheading = styled.p`
  text-align: center;
`;

const Error: React.FC<ErrorProps> = ({ action, ...props }) => {
  return (
    <Container {...props}>
      <ErrorHeading>Oops 💩</ErrorHeading>
      <ErrorSubheading>Något har gått snett här...</ErrorSubheading>
      <ErrorDivider />
      <Action action={action} />
    </Container>
  );
};

export default Error;
