import React, { useCallback } from "react";
import shallow from "zustand/shallow";
import { ErrorHeading, ErrorSubheading } from ".";
import SignInContainer from "../../containers/sign-in";
import useUserStore from "../../state/user-store";
import { Action, Container, ErrorDivider, ErrorProps } from "./common";

const Auth: React.FC<ErrorProps> = ({ action, ...props }) => {
  const user = useUserStore((state) => state.user, shallow);
  const displaySignIn = !user || user.isAnonymous;
  const onSignIn = useCallback((success: boolean) => {
    if (success) location.reload();
  }, []);
  return (
    <Container {...props}>
      <ErrorHeading>Sorry 🔒</ErrorHeading>
      <ErrorSubheading>
        Vi kunde dessvärre inte visa innehållet för dig.
      </ErrorSubheading>
      {displaySignIn && (
        <>
          <ErrorSubheading>Du kanske vill testa att logga in?</ErrorSubheading>
          <SignInContainer
            displayText={false}
            displayAnonymousAuth={false}
            onSignIn={onSignIn}
          />
        </>
      )}
      <ErrorDivider />
      <Action action={action} />
    </Container>
  );
};

export default Auth;
