import React from "react";
import { ErrorHeading, ErrorSubheading } from ".";
import { Action, Container, ErrorDivider, ErrorProps } from "./common";

const Server: React.FC<ErrorProps> = ({ action, ...props }) => {
  return (
    <Container {...props}>
      <ErrorHeading>Ojdå 😳</ErrorHeading>
      <ErrorSubheading>
        Något verkar ha pajjat... Försök igen om en stund!
      </ErrorSubheading>
      <ErrorDivider />
      <Action action={action} />
    </Container>
  );
};

export default Server;
