import React from "react";
import { ErrorHeading, ErrorSubheading } from ".";
import { Action, Container, ErrorDivider, ErrorProps } from "./common";

const NotFound: React.FC<ErrorProps> = ({ action, ...props }) => {
  return (
    <Container {...props}>
      <ErrorHeading>Oops 😔</ErrorHeading>
      <ErrorSubheading>
        Vi lyckades inte hitta det du sökte efter...
      </ErrorSubheading>
      <ErrorDivider />
      <Action action={action} />
    </Container>
  );
};

export default NotFound;
