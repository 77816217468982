import React from "react";
import { ErrorHeading, ErrorSubheading } from ".";
import { Action, Container, ErrorDivider, ErrorProps } from "./common";

const Offline: React.FC<ErrorProps> = ({ action, ...props }) => {
  return (
    <Container {...props}>
      <ErrorHeading>Oops 😢</ErrorHeading>
      <ErrorSubheading>Kunde inte nå servern...</ErrorSubheading>
      <ErrorDivider />
      <Action action={action} />
    </Container>
  );
};

export default Offline;
