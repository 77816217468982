import styled from "@emotion/styled";
import React from "react";
import Error from "../../components/error";
import Auth from "../../components/error/auth";
import NotFound from "../../components/error/not-found";
import Offline from "../../components/error/offline";
import Server from "../../components/error/server";
import { reportError } from "../../libs/sentry/logger";
import { FetchError } from "../../types/error";
import { MainContainer } from "../main-container";

interface Props {
  error: FetchError;
}

const ErrorContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 24rem;
`;

const ErrorChildren: React.FC<Props> = ({ error }) => {
  if (error.message === "Failed to fetch") {
    return <Offline />;
  }

  switch (error.errorCode) {
    case 401:
      return <Auth />;
    case 404:
      return <NotFound />;
    case 500:
      return <Server />;
    default:
      return <Error />;
  }
};

const ErrorHandler: React.FC<Props> = ({ error }) => {
  reportError(error);
  return (
    <MainContainer>
      <ErrorContainer>
        <ErrorChildren error={error} />
      </ErrorContainer>
    </MainContainer>
  );
};

export default ErrorHandler;
